import React from "react"
import styled from "styled-components"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import Card from "../product/card/Card"
import LineHeading from "../ui/LineHeading"

const Contianer = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  padding: 6rem 0 0;
`

const Products = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.75rem;

  > div {
    padding: 0 0.75rem;
  }

  a {
    padding: 1.5rem 0 6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 0;

    > div {
      padding: 0;
    }

    a {
      padding: 3rem 3rem 6rem;
    }
  }
`

export default ({ columns, products, heading, path }) => {
  if (!products) return null

  return (
    <Contianer>
      {heading && <LineHeading>{heading}</LineHeading>}
      <Products>
        {products.map((product) => {
          return <Card key={product._id} product={product} columns={columns} path={path} />
        })}
      </Products>
    </Contianer>
  )
}
