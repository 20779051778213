import React, { useContext } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../components/context/BrinkContext"
import { containerSmallMaxWidth } from "../constants"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Link from "../components/Link"
import ProductGrid from "../components/widgets/ProductGrid"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto 6rem;
`

const Title = styled.h1`
  text-align: center;
  padding: 0 3rem;
  line-height: 4rem;
  font-size: 4rem;
`

const TotalProducts = styled.p`
  text-align: center;
  font-size: 1.6rem;
`

export default ({
  data: { sanityCollectionPage, parentCollection },
  pageContext,
  location
}) => {
  const { t } = useTranslation("translations")
  const { languageCode } = useContext(BrinkContext)
  const { pageTitle, slug, collections } = sanityCollectionPage
  const products = collections
    .map((collection) => collection.products)
    .flat(1)
    .filter((product, currentIndex, products) => {
      const existingProductIndex = products.findIndex(p => p._id === product._id)
      return existingProductIndex === currentIndex
    })

  return (
    <Layout
      meta={pageTitle[languageCode] || pageTitle.en}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs>
        {parentCollection ? (
          <>
            {" "}
            <Link to={`/${parentCollection.slug.current}/`}>
              {parentCollection.pageTitle[languageCode] ||
                parentCollection.pageTitle.en}{" "}
            </Link>
            /
            <Link to={`/${parentCollection.slug.current}/${slug.current}/`}>
              {" "}
              {pageTitle[languageCode] || pageTitle.en}
            </Link>
          </>
        ) : (
          <>
            {" "}
            <Link to={`/${slug.current}/`}>
              {pageTitle[languageCode] || pageTitle.en}
            </Link>
          </>
        )}
      </Breadcrumbs>
      <Container>
        <Title>{pageTitle[languageCode] || pageTitle.en}</Title>
        <TotalProducts>
          {products.length} {products.length === 1 ? t("item") : t("items")}
        </TotalProducts>
        <ProductGrid columns="4" products={products} path={location.pathname} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($collectionPageId: String!) {
    sanityCollectionPage(_id: { eq: $collectionPageId }) {
      _id
      pageTitle {
        en
        zh
      }
      slug {
        current
      }
      collections {
        products {
          _id
          displayName {
            en
            zh
          }
          slug {
            current
          }
          variants {
            ...ProductVariants
          }
        }
      }
    }
    parentCollection: sanityCollectionPage(
      childCollections: { elemMatch: { _id: { eq: $collectionPageId } } }
    ) {
      slug {
        current
      }
      pageTitle {
        en
        zh
      }
    }
  }
`
