import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { usePage } from "../context/PageContext"
import { BrinkContext } from "../context/BrinkContext"
import { containerSmallMaxWidth, MEDIA_MIN_X_LARGE } from "../../constants"
import Link from "../../components/Link"

const Container = styled.div`
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 2rem 3rem 1.7rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  max-width: ${containerSmallMaxWidth};
  margin: 14.2rem auto 0;
  line-height: 1.6rem;

  ${MEDIA_MIN_X_LARGE} {
    font-size: 1.3rem;
    padding: 2.2rem 0 1rem;
  }

  a {
    color: ${(p) => p.theme.colors.black};
  }
`

export default ({ location, children, ...other }) => {
  const { headerMenu } = usePage()
  const { languageCode } = useContext(BrinkContext)
  const { t } = useTranslation("translations")

  return (
    <Container {...other}>
      <Link to="/">{t("Home")}</Link>
      {location && location.state &&
      location.state.prevPath && location.state.prevPath.split('/').map(crumb =>
        headerMenu.map(item =>
          item.slug.current === crumb ?
          <Link key={item.slug.current} to={`/${item.slug.current}/`}> / {item.pageTitle[languageCode] || item.pageTitle.en}</Link> :
          item.childCollections.map(child => child.slug.current === crumb &&
          <Link key={child.slug.current} to={`/${item.slug.current}/${child.slug.current}/`}> / {child.pageTitle[languageCode] || child.pageTitle.en}</Link>)
        ))} /
      {children}
    </Container>
  )
}
